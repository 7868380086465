<template>
  <div class="content">
    <h1 v-text="title"></h1>
    <div class="list-download">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="list-download-item d-flex justify-content-between align-items-center"
      >
        <div class="ms-2 me-auto">
          <a
            :href="item.fileUrl"
            target="_blank"
            class="btn btn-warning btn-sm"
            :title="item.fileSource"
            >下載</a
          >
          {{ item.subject }}
        </div>
        <span class="badge bg-info text-dark">{{ item.fileType }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { PublicAPI } from '@/api/index'

export default {
  emits: ['childSubject'],
  data() {
    return {
      items: [],
      title: this.$route.meta.title
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) this.contentList()
    }
  },
  mounted() {
    this.contentList()
  },
  methods: {
    async contentList() {
      let loader = this.$loading.show()
      await PublicAPI.Download()
        .then(response => {
          this.items = response.data
        })
        .catch(err => {
          console.log(err)
        })
      loader.hide()
    },
    getLink(url, link) {
      return link == '' ? url : link
    }
  }
}
</script>

<style>
.list-download {
  margin-bottom: 3rem;
  border-bottom: 1px solid #ccc;
}
.list-download-item {
  padding: 1rem 0.5rem;
}
.list-download-item + .list-download-item {
  border-top: 1px solid #ccc;
}
</style>
